import 'animate.css';
import { Spin } from 'antd';
import { history, Locale, RenderRoutes } from 'egenie-common';
import * as MITO from 'egenie-monitor-web';
import React from 'react';
import ReactDOM from 'react-dom';
import './global.less';
import { routes } from './routes';
import NProgress from 'nprogress';

if (process.env.NODE_ENV !== 'development') {
  MITO.init({
    trackDsn: '/api/bury/point/report', // 服务器接口地址
    // debug: true,
    silentVue: true, // 不监控Vue的错误
    silentError: true, // 不监控报错
    silentConsole: false, // 监控报错Console
    silentHashchange: true,
    silentUnhandledrejection: true, // 不监控unhandledrejection
    apikey: '衫海精PC',
  });
}

render();

function render() {
  NProgress.start();

  function Internal() {
    React.useLayoutEffect(() => {
      NProgress.done();
    }, []);

    return (
      <Locale>
        <RenderRoutes
          history={history}
          loading={<Spin size="large"/>}
          routes={routes}
        />
      </Locale>
    );
  }

  ReactDOM.render(<Internal/>, document.getElementById('root'));
}

if (process.env.NODE_ENV === 'development') {
  if (process.env.CLI_TOOL === 'vite') {
    // @ts-ignore
    import.meta.hot.accept();
  } else if (process.env.CLI_TOOL === 'webpack') {
    // @ts-ignore
    module.hot.accept(render);
  }
}

