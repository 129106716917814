import type { MenuDataItem } from 'egenie-common';
import React from 'react';

export const routes: MenuDataItem[] = [
  {
    children: [
      {
        exact: true,
        path: '/',
        redirect: '/egenie-ts-vogue',
      },
      {
        exact: true,
        path: '/egenie-ts-vogue/',
        redirect: '/egenie-ts-vogue/homePage/index',
      },
      {
        path: '/egenie-ts-vogue/strategy/index',
        title: '策略任务',
        exact: true,
        component: React.lazy(() => import('./pages/strategyTask/index')),
      },
      {
        path: '/egenie-ts-vogue/cooperationStall/index',
        title: '合作档口 - 衫海精',
        exact: true,
        component: React.lazy(() => import('./pages/cooperationStall/index')),
      },
      {
        path: '/egenie-ts-vogue/stallDetail/index',
        title: '档口详情',
        exact: true,
        component: React.lazy(() => import('./pages/stallDetail/index')),
      },
      {
        path: '/egenie-ts-vogue/exclusive/index',
        title: '专享推荐 - 衫海精',
        exact: true,
        component: React.lazy(() => import('./pages/exclusive/index')),
      },
      {
        path: '/egenie-ts-vogue/productAnalysis/index',
        title: '平台商品分析',
        exact: true,
        component: React.lazy(() => import('./pages/productAnalysis/productAnalysis')),
      },
      {
        path: '/egenie-ts-vogue/shoppingCart/index',
        title: '进货车',
        exact: true,
        component: React.lazy(() => import('./pages/shoppingCart/index')),
      },
      {
        path: '/egenie-ts-vogue/shelvesNew/index',
        title: '上架',
        exact: true,
        component: React.lazy(() => import('./pages/shelvesNew/index')),
      },
      {
        path: '/egenie-ts-vogue/shelvesDouyin/index',
        title: '抖音上架',
        exact: true,
        component: React.lazy(() => import('./pages/shelvesDouyin/index')),
      },
      {
        path: '/egenie-ts-vogue/cashier/index',
        title: '收银台',
        exact: true,
        component: React.lazy(() => import('./pages/cashier/index')),
      },
      {
        path: '/egenie-ts-vogue/shelvesPdd/index',
        title: '拼多多上架',
        exact: true,
        component: React.lazy(() => import('./pages/shelvesPdd/index')),
      },
      {
        path: '/egenie-ts-vogue/myStyle/index',
        title: '我的款式',
        exact: true,
        component: React.lazy(() => import('./pages/myStyle/index')),
      },
      {
        path: '/egenie-ts-vogue/goodsDetail/index',
        title: '款式详情',
        exact: true,
        component: React.lazy(() => import('./pages/goodsDetail/index')),
      },
      {
        path: '/egenie-ts-vogue/login',
        title: '衫海精-欢迎登录',
        exact: true,
        component: React.lazy(() => import('./pages/login/index')),
      },

      {
        path: '/egenie-ts-vogue/findPassword',
        title: '查找密码',
        exact: true,
        component: React.lazy(() => import('./pages/components/findPassword')),
      },
      {
        path: '/egenie-ts-vogue/register',
        title: '衫海精-新用户注册',
        exact: true,
        component: React.lazy(() => import('./pages/register/index')),
      },
      {
        path: '/egenie-ts-vogue/selectedActivities/index',
        title: '买手精选 - 衫海精',
        exact: true,
        component: React.lazy(() => import('./pages/selectedActivities/index')),
      },
      {
        path: '/egenie-ts-vogue/homePage/index',
        title: '',
        exact: true,
        component: React.lazy(() => import('./pages/homePage/index')),
      },
      {
        path: '/egenie-ts-vogue/searchResult/index',
        title: '',
        exact: true,
        component: React.lazy(() => import('./pages/searchResult')),
      },
      {
        path: '/egenie-ts-vogue/newProducts/index',
        title: '',
        exact: true,
        component: React.lazy(() => import('./pages/goodsListPage')),
      },
      {
        path: '/egenie-ts-vogue/peersChoose/index',
        title: '',
        exact: true,
        component: React.lazy(() => import('./pages/goodsListPage')),
      },
      {
        path: '/egenie-ts-vogue/market/index',
        title: '市场详情',
        exact: true,
        component: React.lazy(() => import('./pages/market/index')),
      },
      {
        path: '/egenie-ts-vogue/exhibitionHall/index',
        title: '展厅',
        exact: true,
        component: React.lazy(() => import('./pages/exhibitionHall/index')),
      },
      {
        path: '/egenie-ts-vogue/afterSaleOrder/index',
        title: '售后管理',
        exact: true,
        component: React.lazy(() => import('./pages/afterSale/index')),
      },
      {
        path: '/egenie-ts-vogue/afterSaleOrderDetail/index',
        title: '售后单详情',
        exact: true,
        component: React.lazy(() => import('./pages/afterSale/afterSaleDetail/index')),
      },
      {
        path: '/egenie-ts-vogue/storeGoodsManagement/index',
        title: '店铺商品管理',
        exact: true,
        component: React.lazy(() => import('./pages/storeGoodsManagement/index')),
      },
      {
        path: '/egenie-ts-vogue/matchGoods/index',
        title: '关联商品',
        exact: true,
        component: React.lazy(() => import('./pages/matchGoods/index')),
      },
      {
        path: '/egenie-ts-vogue/myOrder/index',
        title: '我的订单',
        exact: true,
        component: React.lazy(() => import('./pages/myOrder/index')),
      },
      {
        path: '/egenie-ts-vogue/myOrderDetail/index',
        title: '订单详情',
        exact: true,
        component: React.lazy(() => import('./pages/myOrder/orderDetail/index')),
      },
      {
        path: '/egenie-ts-vogue/shopAuthorization/index',
        title: '店铺授权',
        exact: true,
        component: React.lazy(() => import('./pages/shopAuthorization/index')),
      },
      {
        path: '/egenie-ts-vogue/shopOrder/index',
        title: '店铺订单',
        exact: true,
        component: React.lazy(() => import('./pages/shopOrder/index')),
      },
      {
        path: '/egenie-ts-vogue/confirmOrder/index',
        title: '确认下单',
        exact: true,
        component: React.lazy(() => import('./pages/confirmOrder')),
      },
      {
        path: '/egenie-ts-vogue/shopAfterSaleOrder/index',
        title: '店铺售后管理',
        exact: true,
        component: React.lazy(() => import('./pages/shopAfterSaleManage/index')),
      },
      {
        path: '/egenie-ts-vogue/favoriteStyles/index',
        title: '收藏的款式',
        exact: true,
        component: React.lazy(() => import('./pages/favoriteStyles/index')),
      },
      {
        path: '/egenie-ts-vogue/commonAddress/index',
        title: '常用地址',
        exact: true,
        component: React.lazy(() => import('./pages/commonAddress/index')),
      },
      {
        path: '/egenie-ts-vogue/securityCenter/index',
        title: '安全中心',
        exact: true,
        component: React.lazy(() => import('./pages/securityCenter/index')),
      },
      {
        path: '/egenie-ts-vogue/wechatBind',
        title: '衫海精-欢迎登录',
        exact: true,
        component: React.lazy(() => import('./pages/wechatBind/index')),
      },
      {
        path: '/egenie-ts-vogue/information/index',
        title: '个人信息',
        exact: true,
        component: React.lazy(() => import('./pages/information/index')),
      },
      {
        path: '/egenie-ts-vogue/shelvesKs/index',
        title: '快手上架',
        exact: true,
        component: React.lazy(() => import('./pages/shelvesKs/index')),
      },
      {
        path: '/egenie-ts-vogue/findStall/index',
        title: '找档口',
        exact: true,
        component: React.lazy(() => import('./pages/findStall/index')),
      },
      {
        path: '/egenie-ts-vogue/shelvesWxVideo/index',
        title: '视频号上架',
        exact: true,
        component: React.lazy(() => import('./pages/shelvesWxVideo/index')),
      },

      {
        path: '/egenie-ts-vogue/distributor/stallDetail/index',
        title: '档口详情',
        exact: true,
        component: React.lazy(() => import('./pages/stallDetail/index')),
      },
      {
        path: '/egenie-ts-vogue/distributor/goodsDetail/index',
        title: '款式详情',
        exact: true,
        component: React.lazy(() => import('./pages/goodsDetail/index')),
      },
      {
        path: '/egenie-ts-vogue/distributor/shoppingCart/index',
        title: '进货车',
        exact: true,
        component: React.lazy(() => import('./pages/shoppingCart/index')),
      },
      {
        path: '/egenie-ts-vogue/distributor/confirmOrder/index',
        title: '确认下单',
        exact: true,
        component: React.lazy(() => import('./pages/confirmOrder')),
      },
      {
        path: '/egenie-ts-vogue/distributor/myOrder/index',
        title: '我的订单',
        exact: true,
        component: React.lazy(() => import('./pages/myOrder/index')),
      },
      {
        path: '/egenie-ts-vogue/shelvesDouyinProvider/index',
        title: '抖音(供应商)上架',
        exact: true,
        component: React.lazy(() => import('./pages/shelvesDouyinProvider/index')),
      },
      {
        path: '/egenie-ts-vogue/liveGoods/index',
        title: '直播专区',
        exact: true,
        component: React.lazy(() => import('./pages/liveGoods')),
      },
      {
        path: '/egenie-ts-vogue/batchShelvesPdd/index',
        title: '拼多多批量上架',
        exact: true,
        component: React.lazy(() => import('./pages/batchShelvesPdd/index')),
      },
    ],
  },
];

